import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import { Environment } from './enums';

export const charityOrganizations = [
  { 'friends-of-the-san-juans': 'Friends of the San Juans', code: 'CHARITY_ID_FSJ' },
  { 'northwest-straits-foundation': 'Northwest Straits Foundation', code: 'CHARITY_ID_NSF' },
  { 'puget-soundkeeper-alliance': 'Puget Soundkeeper Alliance', code: 'CHARITY_ID_PSA' },
];

/**
 * Returns a capitalized string.
 * @param {string} str - The string to capitalize.
 * @returns {string|null} The capitalized string, or null if input is invalid.
 */
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }

  return (str.charAt(0).toUpperCase() + str.slice(1)).trim();
};

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const getProductImages = images => {
  if (!Array.isArray(images)) {
    return [];
  }
  return images
    .map(
      item =>
        item?.attributes?.variants &&
        (item?.attributes?.variants['scaled-medium']?.url ||
          item?.attributes?.variants['listing-card-2x']?.url)
    )
    .filter(Boolean);
};
export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

const isImage = ['gif', 'jpg', 'jpeg', 'png', 'tiff', 'bmp', 'heic', 'svg', 'webp'];
const isVideo = ['mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'mp4', 'mov', 'wmv', 'flv', 'avi', 'hevc'];

export const getAttachmentType = att => {
  const attachment = att?.attributes?.content;
  const extension =
    attachment &&
    attachment
      .split(/[#?]/)[0]
      .split('.')
      .pop()
      .trim();

  const lowerCaseExtension = extension && typeof extension === 'string' && extension.toLowerCase();
  const hasImage = extension && isImage && isImage.includes(lowerCaseExtension);
  const hasVideo = extension && isVideo && isVideo.includes(lowerCaseExtension);
  return hasImage || hasVideo
    ? { type: hasImage ? 'image' : 'video', link: attachment }
    : attachment;
};

export const formatDateWithRelativity = createdAt => {
  // Use Moment.js to parse the createdAt date
  const date = moment(createdAt);

  // Define custom formatting rules
  const formatRules = {
    sameDay: '[Today], h:mm A', // Display "Today" with time for the current day
    nextDay: '[Tomorrow]', // This can remain as per your original structure or adjusted as needed
    nextWeek: 'dddd', // Day of the week for next week
    lastDay: '[Yesterday]', // Display "Yesterday" for the previous day
    lastWeek: '[Last] dddd', // Display "Last" with the day of the week for the previous week
    sameElse: 'DD/MM/YYYY', // Default date format for dates that don't match above rules
  };

  // Format the createdAt date using the defined rules
  return date.calendar(null, formatRules);
};

export const keepValidGermanChars = inputString => {
  // This regex matches all characters that aren't part of the German alphabet, numerics or spaces
  const regex = /[^a-zA-Z0-9äöüß ]/g;

  // Remove text enclosed within square brackets followed by a URL enclosed within parentheses
  const updatedString = inputString.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');

  // Replace all matches with an empty string
  return updatedString.replace(regex, ' ');
};
export const shuffleArray = arr => {
  const shuffledArray = [...arr];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const getBlogTags = blogs => {
  const tags = [];
  for (let i = 0; i < blogs?.length; i++) {
    const blog = blogs[i];
    tags.push(blog?.attributes?.tags);
  }
  const filteredTags = Array.from(new Set(tags.flat()));
  return tags ? filteredTags : [];
};

export const getDefaultLocale = () => {
  const defaultLocale =
    typeof window !== 'undefined' && window.localStorage.getItem('selectedLanguage');

  return defaultLocale || 'de';
};

export const sortArrayByLabel = arr => {
  const defaultLocale = getDefaultLocale();

  const collator = new Intl.Collator(defaultLocale, { sensitivity: 'base' });

  return (
    isArrayLength(arr) &&
    arr.sort((a, b) => {
      return collator.compare(a.label, b.label);
    })
  );
};

/**
 * Truncates a string to the specified length and adds an ellipsis if necessary.
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length of the text allowed before truncating.
 * @returns {string} The truncated text with an ellipsis if it was cut off.
 */
export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
};

// Helper export  to check if exactly one of the values is truthy and value1 is a non-empty array
export function isOneTruthyAndValidArray(value1, value2) {
  // Check if value1 is a non-empty array and value2 is falsy
  const isValue1ValidArray = Array.isArray(value1) && value1.length > 0;
  // Check if exactly one of the values is truthy
  return (isValue1ValidArray && !value2) || (!isValue1ValidArray && value2);
}

export const extractSeoCategoryData = data => {
  const title = data?.attributes?.title;
  const description = data?.attributes?.description;
  const content = data?.attributes?.content;
  const heading = data?.attributes?.heading;
  const url = data?.attributes?.url;
  const headingImage = `${process.env.REACT_APP_STRAPI_BASE_URL}${data?.attributes?.headingImage?.data?.attributes?.url}`;

  return {
    title,
    description,
    content,
    heading,
    headingImage,
    url,
  };
};

const requiredMetadataProps = ['timestamp', 'echoToken'];
const requiredAddressProps = ['addressLine1', 'town', 'countryIso', 'postcode'];
const requiredGuestProps = ['id', 'firstName', 'lastName', 'email', 'telephoneNumber'];
const requiredReservationProps = [
  'reservationId',
  'checkIn',
  'checkOut',
  'channel',
  'creationDate',
];

const requiredReservationsProps = ['id', 'reservationId'];
const requiredVerificationProps = ['verificationId'];

const hasRequiredProps = (obj, requiredProps) => {
  return requiredProps.every(prop => obj.hasOwnProperty(prop) && obj[prop]);
};

const checkRequiredFields = fieldsToCheck => {
  return fieldsToCheck.every(({ object, requiredProps }) => {
    if (object) {
      return hasRequiredProps(object, requiredProps);
    }
    return false;
  });
};

export const checkRequiredProps = screeningApibody => {
  const { metadata, guest, listing, reservation } = screeningApibody;

  const fieldsToCheck = [
    { object: metadata, requiredProps: requiredMetadataProps },
    { object: guest, requiredProps: requiredGuestProps },
    { object: reservation, requiredProps: requiredReservationProps },
    { object: listing?.address, requiredProps: requiredAddressProps },
  ];

  return checkRequiredFields(fieldsToCheck);
};

export const checkCancelArgs = cancelArgs => {
  const { metadata, reservation, verification } = cancelArgs;

  const fieldsToCheck = [
    { object: metadata, requiredProps: requiredMetadataProps },
    { object: reservation, requiredProps: requiredReservationsProps },
    { object: verification, requiredProps: requiredVerificationProps },
  ];

  return checkRequiredFields(fieldsToCheck);
};

const convertToDecimalDegrees = (degrees, minutes = 0, seconds = 0) => {
  return degrees + minutes / 60 + seconds / 3600;
};

const convertFromDecimalDegreesToDDM = decimal => {
  const degrees = Math.floor(Math.abs(decimal));
  const minutes = (Math.abs(decimal) - degrees) * 60;
  const direction = decimal >= 0 ? 'N' : 'S'; // Assuming North/South for latitude or East/West for longitude
  return { degrees, minutes, direction };
};

const convertFromDecimalDegreesToDMS = decimal => {
  const degrees = Math.floor(Math.abs(decimal));
  const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
  const minutes = Math.floor(minutesDecimal);
  const seconds = (minutesDecimal - minutes) * 60;
  const direction = decimal >= 0 ? 'N' : 'S'; // Assuming North/South for latitude or East/West for longitude
  return { degrees, minutes, seconds, direction };
};

export const createLatLongFromCoordinates = (values, format, formApi) => {
  let lat, lng;

  // Convert entered values to DD (Decimal Degrees)
  if (format === 'DD') {
    lat = parseFloat(values.latitudeDD);
    lng = parseFloat(values.longitudeDD);
  } else if (format === 'DDM') {
    lat = convertToDecimalDegrees(
      parseInt(values.latitudeDegrees),
      parseFloat(values.latitudeMinutes)
    );
    lng = convertToDecimalDegrees(
      parseInt(values.longitudeDegrees),
      parseFloat(values.longitudeMinutes)
    );
    // Use the original direction to ensure consistency
    lat *= values.latitudeDirection === 'S' ? -1 : 1;
    lng *= values.longitudeDirection === 'W' ? -1 : 1;
  } else if (format === 'DMS') {
    lat = convertToDecimalDegrees(
      parseInt(values.latitudeDegreesDMS),
      parseInt(values.latitudeMinutesDMS),
      parseFloat(values.latitudeSecondsDMS)
    );
    lng = convertToDecimalDegrees(
      parseInt(values.longitudeDegreesDMS),
      parseInt(values.longitudeMinutesDMS),
      parseFloat(values.longitudeSecondsDMS)
    );
    // Use the original direction to ensure consistency
    lat *= values.latitudeDirectionDMS === 'S' ? -1 : 1;
    lng *= values.longitudeDirectionDMS === 'W' ? -1 : 1;
  } else {
    throw new Error('Unsupported coordinate format');
  }

  // Correct the direction in case of discrepancies
  const correctedLatDirection = lat >= 0 ? 'N' : 'S';
  const correctedLngDirection = lng >= 0 ? 'E' : 'W';

  // Ensure directions align with user-selected values
  const finalLatDirection =
    format === 'DDM' || format === 'DMS' ? values.latitudeDirection : correctedLatDirection;
  const finalLngDirection =
    format === 'DDM' || format === 'DMS' ? values.longitudeDirection : correctedLngDirection;

  // Update form values in all formats
  formApi.change('latitudeDD', lat);
  formApi.change('longitudeDD', lng);

  const {
    degrees: latDegDDM,
    minutes: latMinDDM,
  } = convertFromDecimalDegreesToDDM(lat);
  const {
    degrees: lngDegDDM,
    minutes: lngMinDDM,
  } = convertFromDecimalDegreesToDDM(lng);

  formApi.change('latitudeDegrees', latDegDDM);
  formApi.change('latitudeMinutes', latMinDDM);
  formApi.change('latitudeDirection', finalLatDirection);
  formApi.change('longitudeDegrees', lngDegDDM);
  formApi.change('longitudeMinutes', lngMinDDM);
  formApi.change('longitudeDirection', finalLngDirection);

  const {
    degrees: latDegDMS,
    minutes: latMinDMS,
    seconds: latSecDMS,
  } = convertFromDecimalDegreesToDMS(lat);
  const {
    degrees: lngDegDMS,
    minutes: lngMinDMS,
    seconds: lngSecDMS,
  } = convertFromDecimalDegreesToDMS(lng);

  formApi.change('latitudeDegreesDMS', latDegDMS);
  formApi.change('latitudeMinutesDMS', latMinDMS);
  formApi.change('latitudeSecondsDMS', latSecDMS);
  formApi.change('latitudeDirectionDMS', finalLatDirection);
  formApi.change('longitudeDegreesDMS', lngDegDMS);
  formApi.change('longitudeMinutesDMS', lngMinDMS);
  formApi.change('longitudeSecondsDMS', lngSecDMS);
  formApi.change('longitudeDirectionDMS', finalLngDirection);

  return {
    latitude: lat,
    longitude: lng,
  };
};

export const updateCoordinatesFields = (latitude, longitude, formApi) => {
  // Update Decimal Degrees (DD)
  formApi.change('latitudeDD', latitude);
  formApi.change('longitudeDD', longitude);

  // Calculate Degrees Decimal Minutes (DDM)
  const latDegrees = Math.floor(Math.abs(latitude));
  const latMinutes = (Math.abs(latitude) - latDegrees) * 60;
  const latDirection = latitude >= 0 ? 'N' : 'S';

  const lonDegrees = Math.floor(Math.abs(longitude));
  const lonMinutes = (Math.abs(longitude) - lonDegrees) * 60;
  const lonDirection = longitude >= 0 ? 'E' : 'W';

  formApi.change('latitudeDegrees', latDegrees);
  formApi.change('latitudeMinutes', latMinutes.toFixed(2));
  formApi.change('latitudeDirection', latDirection);
  formApi.change('longitudeDegrees', lonDegrees);
  formApi.change('longitudeMinutes', lonMinutes.toFixed(2));
  formApi.change('longitudeDirection', lonDirection);

  // Calculate Degrees Minutes Seconds (DMS)
  const latSeconds = (latMinutes % 1) * 60;
  const lonSeconds = (lonMinutes % 1) * 60;

  formApi.change('latitudeDegreesDMS', latDegrees);
  formApi.change('latitudeMinutesDMS', Math.floor(latMinutes));
  formApi.change('latitudeSecondsDMS', latSeconds.toFixed(2));
  formApi.change('latitudeDirectionDMS', latDirection);

  formApi.change('longitudeDegreesDMS', lonDegrees);
  formApi.change('longitudeMinutesDMS', Math.floor(lonMinutes));
  formApi.change('longitudeSecondsDMS', lonSeconds.toFixed(2));
  formApi.change('longitudeDirectionDMS', lonDirection);
};

export const getEnvironment = () => {
  return process.env.REACT_APP_ENV;
};

export const getFlexUrl = env => {
  if (env === Environment.DEVELOPMENT) {
    return process.env.REACT_APP_TEST_FLEX_CONSOLE_URL || 'https://console.sharetribe.com/o/onbuoy/m/onbuoy-test';
  }
  return process.env.REACT_APP_LIVE_FLEX_CONSOLE_URL || "https://console.sharetribe.com/o/onbuoy/m/onbuoy";
};

export const convertGeolocationToParams = (geolocation) => {
  const { lat, lng } = geolocation;

  // Helper function to convert decimal to degrees, minutes, seconds, and direction
  const convertToDMS = (decimal, isLatitude) => {
    const direction = decimal >= 0
      ? isLatitude ? "N" : "E"
      : isLatitude ? "S" : "W";

    const absDecimal = Math.abs(decimal);
    const degrees = Math.floor(absDecimal);
    const minutesDecimal = (absDecimal - degrees) * 60;
    const minutes = Math.floor(minutesDecimal);
    const seconds = (minutesDecimal - minutes) * 60;

    return {
      decimalDegrees: decimal,
      degrees: degrees,
      degreesDMS: degrees,
      minutes: minutesDecimal, // Decimal representation of minutes
      minutesDMS: minutes, // Rounded-down minutes
      secondsDMS: seconds.toFixed(5), // Rounded seconds to 5 decimal points
      direction: direction,
      directionDMS: direction
    };
  };

  // Convert latitude and longitude
  const latitudeDMS = convertToDMS(lat, true);
  const longitudeDMS = convertToDMS(lng, false);

  // Combine into locationParams format
  return {
    latitude: latitudeDMS.decimalDegrees,
    latitudeDD: latitudeDMS.decimalDegrees,
    latitudeDegrees: latitudeDMS.degrees,
    latitudeDegreesDMS: latitudeDMS.degreesDMS,
    latitudeDirection: latitudeDMS.direction,
    latitudeDirectionDMS: latitudeDMS.directionDMS,
    latitudeMinutes: latitudeDMS.minutes,
    latitudeMinutesDMS: latitudeDMS.minutesDMS,
    latitudeSecondsDMS: latitudeDMS.secondsDMS,
    longitude: longitudeDMS.decimalDegrees,
    longitudeDD: longitudeDMS.decimalDegrees,
    longitudeDegrees: longitudeDMS.degrees,
    longitudeDegreesDMS: longitudeDMS.degreesDMS,
    longitudeDirection: longitudeDMS.direction,
    longitudeDirectionDMS: longitudeDMS.directionDMS,
    longitudeMinutes: longitudeDMS.minutes,
    longitudeMinutesDMS: longitudeDMS.minutesDMS,
    longitudeSecondsDMS: longitudeDMS.secondsDMS,
    locationType: "DD"
  };
};

export const showDashboardLink = (user) => {
  [ 'darrengaustin@gmail.com', 'crew@onbuoy.com', 'darren@onbuoy.com'].includes(user?.attributes?.email)
}